import React from 'react';
import { useParams, useRouteMatch } from "react-router-dom";
import tw, {styled} from "twin.macro";

const Header = styled.header`
  ${tw`flex items-center bg-primary-900 p-2`}

  & > * {
    ${tw`text-white`}
  }
`;

export default function Breadcrumb(props) {
  const params = useParams();
  const tenantMatch = useRouteMatch('/:tenantid');
  const hashtagMatch = useRouteMatch('/:tenantid/hashtags/:hashtag');

  console.log(params, tenantMatch, hashtagMatch);


  return (
    <Header>
      {props.children.map((child, index) => (
        <span>
          {child}
          {(index < props.children.length - 1) ? (<span>&nbsp;&gt;&nbsp;</span>) : null}
        </span>
      ))}
    </Header>
  )
}