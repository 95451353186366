import React, {useEffect, useState} from 'react';
import { useRouteMatch, Link } from "react-router-dom";
import axios from 'axios';
import {get} from 'lodash';
import { css } from "styled-components/macro"; //eslint-disable-line
import tw from 'twin.macro'
import graphql from 'babel-plugin-relay/macro';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {AreaChart, Area, Tooltip, XAxis, YAxis} from 'recharts';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Breadcrumb from 'screens/App/components/Breadcrumb';

const moment = extendMoment(Moment);

const Wrapper = tw.div`
  p-3 mx-auto
`;

const Table = tw.table`
  table-auto
`;

const TableHeader = tw.th`
  px-4 py-2
`;

const TableRow = tw.tr`
  odd:bg-gray-100
`;

const TableCell = tw.td`
  border px-4 py-2
`;


export default function DashboardScreen(props) {
  const match = useRouteMatch();
  const [data, setData] = useState({
    hashtags: []
  });
  const range = moment.range(moment().subtract(14, 'days'), moment());
  const dates = Array.from(range.by('day')).reverse();

  const query = useLazyLoadQuery(graphql`
    query DashboardScreenQuery($id: ID!) {
      tenant(id: $id) {
        id
        name
        hashtagDataUrl

        hashtags {
          id
          hashtag
        }
      }
    }
  `, {
    id: match.params.tenant
  }, {fetchPolicy: 'store-or-network'});


  useEffect(() => {
    if (!query || !query.tenant) return;

    axios.get(query.tenant.hashtagDataUrl).then(response => {
      return response.data;
    }).then((data) => {
      setData(data);
    })
  }, [query]);

  return (
    <div>
      <Breadcrumb>{props.breadcrumb}</Breadcrumb>
      <Wrapper>
        <Table>
          <thead>
            <tr>
              <TableHeader>Hashtag</TableHeader>
              <TableHeader />
              {dates.map(date => (
                <TableHeader key={date.format('YYYY-MM-DD')}>{date.format('MMM Do')}</TableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {query.tenant.hashtags.map((hashtag, index) => (
              <React.Fragment key={hashtag.hashtag}>
                <TableRow>
                  <TableCell>
                    <Link to={`${match.url}/hashtags/${hashtag.id}`}>#{hashtag.hashtag}</Link>
                  </TableCell>
                  <TableCell>
                    {data.hashtags.find(search => search.hashtag === hashtag.hashtag) ? (
                      <AreaChart
                        width={400} height={40} data={dates.map(date => ({
                          label: date.format('YYYY-MM-DD'),
                          total: get(data.hashtags.find(search => search.hashtag === hashtag.hashtag).series.find(item => date.isSame(item.bucket, 'day')), 'total', 0)
                        }))}
                        margin={{top: 3, right: 3, left: 3, bottom: 3}}
                      >
                        <XAxis hide={true} dataKey="label" />
                        <YAxis hide={true} type="number" allowDataOverflow={false} />
                        <Area type='monotone' dataKey="total" stroke='#8884d8' fill='#8884d8' />
                        <Tooltip />
                      </AreaChart>
                    ) : (
                      <span>Loading ...</span>
                    )}
                  </TableCell>
                  {dates.map(date => (
                    <TableCell key={date.format('YYYY-MM-DD')} css={[tw`text-right`]}>
                      {
                        get(
                          get(
                              data.hashtags.find(search => search.hashtag === hashtag.hashtag),
                              'series',
                              []
                          )
                          .find(item => date.isSame(item.bucket, 'day')),
                          'total',
                          'N/A'
                        )}
                      </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Wrapper>
    </div>
  )
}