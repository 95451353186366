import React from "react";
import tw from "twin.macro";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faHashtag, faAt, faCogs, faFileExport, faUser } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "./components/Hero";
import Features from "components/_features/ThreeColWithSideImage";
import MainFeature from "components/_features/TwoColWithButton";
import FeatureWithSteps from "components/_features/TwoColWithSteps";
import worldImageSrc from './assets/world-image.png';
import Footer from '../../components/Footer';

export default () => {
  const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <StyledDiv className="App">
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            The only service for monitoring <HighlightedText>Instagram Stories.</HighlightedText>
          </>
        }
        description={
          <>
            As a small but experienced company we are able to offer innovative and custom solutions to suit your data needs.
          </>
        }
        cards={[
          {
            title: "Hashtags",
            description: "With our service you can monitor what instagram stories are posted to hashtags across the world.",
            image: (<FontAwesomeIcon icon={faHashtag} />)
          },
          {
            title: "Mentions",
            description: "Monitor your influencer campaigns by tracking incoming mentions to your handle.",
            image: (<FontAwesomeIcon icon={faAt} />)
          },
          {
            title: "Influencers & Competitors",
            description: "Keep track of specific high profile influencers or competitors.",
            image: (<FontAwesomeIcon icon={faUser} />)
          },
          {
            title: "Feed/Posts",
            description: "We offer a variety of newsfeed tracking options.",
            image: (<FontAwesomeIcon icon={faInstagram} />)
          },
          {
            title: "Exports",
            description: "We can provide data in any shape or format you prefer, or integrate with your existing data warehouse solution.",
            image: (<FontAwesomeIcon icon={faFileExport} />)
          },
          {
            title: "Customization",
            description: "As experts in social media data we can work with you to build a solution for any of your data needs.",
            image: (<FontAwesomeIcon icon={faCogs} />)
          }
        ]}
      />
      <MainFeature
        subheading = "Hashtag monitoring"
        heading={(
          <>
            Global insight into <span tw="text-primary-500">Instagram Stories.</span>
          </>
        )}
        description="By tracking instagram hashtags in various local languages you can get a day to day view of what the world is currently talking about."
        
        imageSrc={worldImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonText={"Learn more"}
        primaryButtonUrl="/hashtag-monitoring"
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        steps={[
          {
            heading: "Register",
            description: "Create an account by getting in touch."
          },
          {
            heading: "Track",
            description: "Let us know what hashtags you want to mention."
          },
          {
            heading: "Export",
            description: "View the data on our dashboard or export in your format of choice."
          }
        ]}
        textOnLeft={false}
        imageSrc={null}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/*<MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />*/}
      {/*<Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        ]}
      />*/}
      {/*<GetStarted/>*/}
      <Footer />
    </StyledDiv>
  );
}
