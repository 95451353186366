/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MediaDisplay_mediaItem$ref = any;
export type MediaType = "IMAGE" | "VIDEO" | "%future added value";
export type HashtagScreenQueryVariables = {|
  id: string
|};
export type HashtagScreenQueryResponse = {|
  +hashtag: ?{|
    +hashtag: string,
    +content: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +__typename: string,
          +timestamp?: any,
          +hashtags?: ?$ReadOnlyArray<?{|
            +hashtag: string
          |}>,
          +user?: {|
            +username: string,
            +followers: ?number,
          |},
          +media?: ?{|
            +type: MediaType,
            +publicUrl: string,
            +$fragmentRefs: MediaDisplay_mediaItem$ref,
          |},
        |}
      |}>
    |},
  |}
|};
export type HashtagScreenQuery = {|
  variables: HashtagScreenQueryVariables,
  response: HashtagScreenQueryResponse,
|};
*/


/*
query HashtagScreenQuery(
  $id: ID!
) {
  hashtag(id: $id) {
    hashtag
    content(first: 25) {
      edges {
        node {
          id
          __typename
          ... on InstagramStory {
            id
            timestamp
            hashtags {
              hashtag
              id
            }
            user {
              username
              followers
            }
            media {
              ...MediaDisplay_mediaItem
              type
              publicUrl
            }
          }
        }
      }
    }
    id
  }
}

fragment MediaDisplay_mediaItem on MediaItem {
  type
  publicUrl
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hashtag",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "InstagramUser",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "followers",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HashtagScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Hashtag",
        "kind": "LinkedField",
        "name": "hashtag",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ContentItemConnection",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Hashtag",
                            "kind": "LinkedField",
                            "name": "hashtags",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MediaItem",
                            "kind": "LinkedField",
                            "name": "media",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "MediaDisplay_mediaItem"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "InstagramStory",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "content(first:25)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HashtagScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Hashtag",
        "kind": "LinkedField",
        "name": "hashtag",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ContentItemConnection",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Hashtag",
                            "kind": "LinkedField",
                            "name": "hashtags",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MediaItem",
                            "kind": "LinkedField",
                            "name": "media",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "InstagramStory",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "content(first:25)"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22b801ae6f1c1f39598b610c226cbdff",
    "id": null,
    "metadata": {},
    "name": "HashtagScreenQuery",
    "operationKind": "query",
    "text": "query HashtagScreenQuery(\n  $id: ID!\n) {\n  hashtag(id: $id) {\n    hashtag\n    content(first: 25) {\n      edges {\n        node {\n          id\n          __typename\n          ... on InstagramStory {\n            id\n            timestamp\n            hashtags {\n              hashtag\n              id\n            }\n            user {\n              username\n              followers\n            }\n            media {\n              ...MediaDisplay_mediaItem\n              type\n              publicUrl\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment MediaDisplay_mediaItem on MediaItem {\n  type\n  publicUrl\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9328876b37e80384ac6fa73ea8510f17';

module.exports = node;
