/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type MediaType = "IMAGE" | "VIDEO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaDisplay_mediaItem$ref: FragmentReference;
declare export opaque type MediaDisplay_mediaItem$fragmentType: MediaDisplay_mediaItem$ref;
export type MediaDisplay_mediaItem = {|
  +type: MediaType,
  +publicUrl: string,
  +$refType: MediaDisplay_mediaItem$ref,
|};
export type MediaDisplay_mediaItem$data = MediaDisplay_mediaItem;
export type MediaDisplay_mediaItem$key = {
  +$data?: MediaDisplay_mediaItem$data,
  +$fragmentRefs: MediaDisplay_mediaItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaDisplay_mediaItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicUrl",
      "storageKey": null
    }
  ],
  "type": "MediaItem",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '593aa420a39e42d4853f8a4aaab99369';

module.exports = node;
