import React from 'react';
import { useRouteMatch, useLocation, Link } from "react-router-dom";
import {property, sample} from 'lodash';
//import { css } from "styled-components/macro"; //eslint-disable-line
import tw, {styled} from 'twin.macro';
import graphql from 'babel-plugin-relay/macro';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import Breadcrumb from 'screens/App/components/Breadcrumb';
import MediaDisplay from 'screens/App/components/MediaDisplay';

const Wrapper = tw.div`
  p-3 mx-auto
`;

const ContentItem = styled.div`
  position: relative;
  ${tw`float-left mb-4 mr-4 rounded-md border-solid border-2 border-gray-900 overflow-hidden`}
`;

const Meta = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  ${tw`bg-primary-900 bg-opacity-75 text-white p-1`}

  & > * {
    ${tw`flex justify-between`}
  }
`;

const width = 300;
const height = width * 16 / 9;
const StyledMediaDisplay = styled(MediaDisplay)`
  width: ${width}px;
  height: ${height}px;

  ${props => props.demo && `
    filter: blur(10px);
  `}
`;

function toDate(input) {
  return new Date(input);
}

export default function HashtagScreen(props) {
  const match = useRouteMatch();
  const params = match.params;
  const location = useLocation();

  console.log((location.search || '').includes('demo'));

  const query = useLazyLoadQuery(graphql`
    query HashtagScreenQuery($id: ID!) {
      hashtag(id: $id) {
        hashtag

        content(first: 25) {
          edges {
            node {
              id
              __typename
              ... on InstagramStory {
                id
                timestamp
                hashtags {
                  hashtag
                }
                user {
                  username
                  followers
                }
                media {
                  ...MediaDisplay_mediaItem
                  type
                  publicUrl
                }
              }
            }
          }
        }
      }
    }
  `, {
    id: params.hashtag
  }, {fetchPolicy: 'store-or-network'});

  const breadcrumb = props.breadcrumb.concat([<Link to={match.url}>#{query.hashtag.hashtag}</Link>]);
  const demo = (location.search || '').includes('demo');

  let username = (user) => {
    if (demo) return sample([
      'winoffense',
      'deliverrice',
      'surroundrussian',
      'nerveevacuate',
      'gearshell',
      'gymnasticsmount',
      'crowdedget',
      'cahootsscarce',
      'unpleasantkosher',
      'probabletrek',
      'taskferry',
      'absurdorangutan',
      'classicelder',
      'porcupinecache',
      'diligenthomework',
      'poultrychief',
      'kennelmortified',
      'grumpybobbin',
      'eggsblunt',
      'houndwrist',
      'foresterevaporate',
      'ragfasten',
      'lawfuldungbomb',
      'sleddertottering',
      'giddytasty',
      'lollygagmitt',
      'dittyfloat',
      'easystork',
      'skatecohort',
      'millicentinfinite',
      'xiphoidhighway',
      'bustardstoic',
      'commercialcola',
      'humpmutation',
      'besmirchhellish',
      'scupperprairiedog',
      'delectableideal',
      'oraclegel',
      'nincompoopserene'
    ]);
    return user.username;
  }


  let followers = (user) => {
    if (demo) return (0.7 + Math.random()).toFixed(2) + 'm'
    return user.followers || 'N/A'
  };

  return (
    <div>
      <Breadcrumb>{breadcrumb}</Breadcrumb>
      <Wrapper>
        <a href={`https://www.instagram.com/explore/tags/${query.hashtag.hashtag}/`} target="_blank" rel="noopener noreferrer" title="View hashtag on Instagram">View #{query.hashtag.hashtag} on instagram</a>
        <br />
        <br />
        <div>
          {query.hashtag.content.edges.map(property('node')).map(item => (
            <ContentItem key={item.id}>
              <Meta>
                <div>{toDate(item.timestamp).toLocaleDateString()} {toDate(item.timestamp).toLocaleTimeString()}</div>
                <div>@{username(item.user)} <span><FontAwesomeIcon icon={faUsers} /> {followers(item.user)}</span></div>
              </Meta>

              <StyledMediaDisplay demo={demo} mediaItem={item.media} />
            </ContentItem>
          ))}
        </div>
        </Wrapper>
    </div>
  )
}