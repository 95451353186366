import React from 'react';
import tw from "twin.macro";
// import styled from "styled-components";
import {Link} from 'react-router-dom';
import { css } from "styled-components/macro"; //eslint-disable-line
import {NegativeLogo} from 'components/Logo';
import {LogoutActionWrapper} from 'components/LogoutButton';

import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'react-relay/hooks';

const Header = tw.header`
  flex justify-between items-center
  mx-auto bg-primary-500 p-2
`;

// max-w-screen-xl

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw(Link)`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide 
  text-white hocus:text-white hover:border-white
  pb-1 border-b-2 border-transparent
  cursor-pointer
`;

export default function AppHeader(props) {
  const data = useFragment(
    graphql`
      fragment Header_viewer on Viewer {
        tenants {
          id
          name
        }
      }
    `,
    props.viewer,
  );

  console.log(data);

  return (
    <Header>
      <Link to="/dashboard">
        <NegativeLogo />
      </Link>
      <NavLinks>
        <NavLink as={LogoutActionWrapper}>
          <span>Logout</span>
        </NavLink>
      </NavLinks>
    </Header>
  )
}