import React from 'react';
import { useRouteMatch } from "react-router-dom";
import graphql from 'babel-plugin-relay/macro';
import {useLazyLoadQuery} from 'react-relay/hooks';
import { Switch, Route, Link } from "react-router-dom";

import DashboardScreen from './screens/Dashboard';
import HashtagScreen from './screens/Hashtag';

export default function TenantScreen() {
  const match = useRouteMatch();
  const query = useLazyLoadQuery(graphql`
    query TenantScreenQuery($id: ID!) {
      tenant(id: $id) {
        name
      }
    }
  `, {
    id: match.params.tenant
  }, {fetchPolicy: 'store-or-network'})

  const breadcrumb = [
    <Link to={`${match.url}`}>{query.tenant.name}</Link>
  ]

  return (
    <div>
      <React.Suspense fallback={'Loading...'}>
        <Switch>
          <Route path={`${match.path}`} exact={true}>
            <DashboardScreen breadcrumb={breadcrumb} />
          </Route>
          <Route path={`${match.path}/hashtags/:hashtag`}>
            <HashtagScreen breadcrumb={breadcrumb} />
          </Route>
        </Switch>
      </React.Suspense>
    </div>
  )
}