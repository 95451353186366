/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Header_viewer$ref: FragmentReference;
declare export opaque type Header_viewer$fragmentType: Header_viewer$ref;
export type Header_viewer = {|
  +tenants: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
  |}>,
  +$refType: Header_viewer$ref,
|};
export type Header_viewer$data = Header_viewer;
export type Header_viewer$key = {
  +$data?: Header_viewer$data,
  +$fragmentRefs: Header_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c9c37481cdfa8a0ba873b246d5afb18c';

module.exports = node;
