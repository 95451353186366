import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'react-relay/hooks';
import {css} from "styled-components/macro"; //eslint-disable-line
import {styled} from 'twin.macro';

const Wrapper = styled.div`
  height: 100%;

  & img, & video {
    max-height: 100%;
    width: auto;
    margin: 0 auto;
  }
`;

export default function MediaDisplay(props) {
  const data = useFragment(
    graphql`
      fragment MediaDisplay_mediaItem on MediaItem {
        type
        publicUrl
      }
    `,
    props.mediaItem,
  );

  return (
    <Wrapper className={props.className}>
      {data.type === 'IMAGE' && (
        <img src={data.publicUrl} alt="" />
      )}
      {data.type === 'VIDEO' && (
        <video src={data.publicUrl} controls autoPlay={false} />
      )}
    </Wrapper>
  )
}