import React from 'react';
import { css } from "styled-components/macro"; //eslint-disable-line
import tw from 'twin.macro'
import graphql from 'babel-plugin-relay/macro';
import {useLazyLoadQuery} from 'react-relay/hooks';
import { Switch, Route, Redirect, Link } from "react-router-dom";

import AppHeader from './components/Header';
import TenantScreen from './screens/Tenant/TenantScreen';

const Wrapper = tw.div`
  p-3 mx-auto
`;

const TenantLink = tw(Link)`
  block mr-3 float-left
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`

export default function AppScreen() {
  const query = useLazyLoadQuery(graphql`
    query AppScreenQuery {
      viewer {
        tenants {
          id
          name
        }

        ...Header_viewer
      }
    }
  `, {}, {fetchPolicy: 'store-or-network'});

  return (
    <div>
      <AppHeader viewer={query.viewer} />
      <div>
        <React.Suspense fallback={'Loading...'}>
          <Switch>
            <Route path="/dashboard">
              <Wrapper>
                {query.viewer.tenants.map(tenant => (
                  <TenantLink key={tenant.id} to={`/${tenant.id}`}>{tenant.name}</TenantLink>
                ))}
              </Wrapper>
              {query.viewer.tenants.length === 1 && (<Redirect to={`/${query.viewer.tenants[0].id}`} />)}
            </Route>
            <Route path="/:tenant">
              <TenantScreen />
            </Route>
          </Switch>
        </React.Suspense>
      </div>
    </div>
  )
}