/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DashboardScreenQueryVariables = {|
  id: string
|};
export type DashboardScreenQueryResponse = {|
  +tenant: ?{|
    +id: string,
    +name: string,
    +hashtagDataUrl: string,
    +hashtags: ?$ReadOnlyArray<{|
      +id: string,
      +hashtag: string,
    |}>,
  |}
|};
export type DashboardScreenQuery = {|
  variables: DashboardScreenQueryVariables,
  response: DashboardScreenQueryResponse,
|};
*/


/*
query DashboardScreenQuery(
  $id: ID!
) {
  tenant(id: $id) {
    id
    name
    hashtagDataUrl
    hashtags {
      id
      hashtag
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Tenant",
    "kind": "LinkedField",
    "name": "tenant",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hashtagDataUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Hashtag",
        "kind": "LinkedField",
        "name": "hashtags",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hashtag",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "93c13b675a0a706ddf2d1a3d017c869e",
    "id": null,
    "metadata": {},
    "name": "DashboardScreenQuery",
    "operationKind": "query",
    "text": "query DashboardScreenQuery(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    id\n    name\n    hashtagDataUrl\n    hashtags {\n      id\n      hashtag\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '156a30f70af9ec90caf6b873229c19e0';

module.exports = node;
