/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Header_viewer$ref = any;
export type AppScreenQueryVariables = {||};
export type AppScreenQueryResponse = {|
  +viewer: ?{|
    +tenants: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
    |}>,
    +$fragmentRefs: Header_viewer$ref,
  |}
|};
export type AppScreenQuery = {|
  variables: AppScreenQueryVariables,
  response: AppScreenQueryResponse,
|};
*/


/*
query AppScreenQuery {
  viewer {
    tenants {
      id
      name
    }
    ...Header_viewer
    id
  }
}

fragment Header_viewer on Viewer {
  tenants {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenants",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31761ffec6dda518f789b812355136cd",
    "id": null,
    "metadata": {},
    "name": "AppScreenQuery",
    "operationKind": "query",
    "text": "query AppScreenQuery {\n  viewer {\n    tenants {\n      id\n      name\n    }\n    ...Header_viewer\n    id\n  }\n}\n\nfragment Header_viewer on Viewer {\n  tenants {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cfeb2de22b904cdf344f659ca8403e01';

module.exports = node;
