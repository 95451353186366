import React from "react";
import ReactDOM from "react-dom";
import RootScreen from "./RootScreen";
import Modal from "react-modal";
import {
  RelayEnvironmentProvider
} from 'react-relay/hooks';

import RelayEnvironment from './RelayEnvironment';

Modal.setAppElement("#root");

ReactDOM.render(
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <React.Suspense fallback={'Loading...'}>
      <RootScreen />
    </React.Suspense>
  </RelayEnvironmentProvider>,
  document.getElementById("root")
);
