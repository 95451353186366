import React from 'react';
import { useHistory } from "react-router-dom";

export function LoginActionWrapper(props) {
  const history = useHistory();
  const handleClick = () => {
    window.RzsgAuth.popup.login().then(() => {
      history.push('/dashboard');
    });
  };

  return React.cloneElement(props.children, {
    onClick: handleClick,
    style: {
      ...props.children.styles,
      cursor: 'pointer'
    }
  });
}