import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const BaseLogo = tw.div`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const SquareLogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! bg-primary-500 text-white`};

  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 64px;
  line-height: 64px;
  display: inline-block;
  border-radius: 6px;
`;

export const PositiveLogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  span {
    ${tw`bg-primary-500 text-white`}

    line-height: 24px;
    padding: 5px 6px;
    display: inline-block;
    border-radius: 6px;
    margin-right: 10px;
  }
`;

export const NegativeLogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! text-white hocus:text-white`};

  span {
    ${tw`bg-white text-primary-500`}

    line-height: 24px;
    padding: 5px 6px;
    display: inline-block;
    border-radius: 6px;
    margin-right: 10px;
  }
`;

export const NegativeLogoWrapper = styled(BaseLogo)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! text-white hocus:text-white`};

  span {
    ${tw`bg-white text-primary-500`}

    line-height: 24px;
    padding: 5px 6px;
    display: inline-block;
    border-radius: 6px;
    margin-right: 10px;
  }
`;

export function SquareLogo() {
  return (
    <SquareLogoLink href="/">
      R
    </SquareLogoLink>
  );
}

export function PositiveLogo() {
  return (
    <PositiveLogoLink href="/">
      <span>RZSG</span> SOCIAL
    </PositiveLogoLink>
  )
}

export function NegativeLogo() {
  return (
    <NegativeLogoWrapper>
      <span>RZSG</span> SOCIAL
    </NegativeLogoWrapper>
  )
}