import React from 'react';
import { useHistory } from "react-router-dom";

export function LogoutActionWrapper(props) {
  const history = useHistory();
  const handleClick = () => {
    window.RzsgAuth.logout().then(() => {
      history.push('/');
    });
  };

  return (
    <div {...props} onClick={handleClick}>
      {React.cloneElement(props.children, {
        style: {
          ...props.children.styles,
          cursor: 'pointer'
        }
      })}
    </div>
  );
}