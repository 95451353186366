import React from "react";
import tw from "twin.macro";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faHashtag, faGlobe, faFolder, faFileExport, faUser } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "screens/LandingPage/components/Header.js";
import Features from "components/_features/ThreeColWithSideImage";
import MainFeature from "components/_features/TwoColWithButton";
import screenshotA from './assets/hashtags-screenshot-a-small.png';
import screenshotB from './assets/hashtags-screenshot-b-small.png';
import worldImage from './assets/world-image.png';
import Footer from '../../components/Footer';

export default () => {
  const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  // const HighlightedText = tw.span`text-primary-500`;

  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <MainFeature
        headingAs="h1"
        heading={(
          <>
            <span tw="text-primary-500">Hashtag Monitoring</span> for Instagram Stories &amp; Newsfeed
          </>
        )}
        description="Monitor who is posting content about your hashtags. Track competitor campaigns."
        
        imageSrc={screenshotA}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonText={null}
      />
      <Features
        subheading={<Subheading>Features</Subheading>}
        cards={[
          {
            title: "Instagram Stories",
            description: "Monitor content posted to Instagram Stories, explore stories that are no longer live",
            image: (<FontAwesomeIcon icon={faHashtag} />)
          },
          {
            title: "Instagram Newsfeed",
            description: "Track content posted to the newsfeed of popular instagram accounts, monitor your competitor campaigns.",
            image: (<FontAwesomeIcon icon={faInstagram} />)
          },
          {
            title: "Popular content",
            description: "Filter stories & posts based on how many followers the poster has.",
            image: (<FontAwesomeIcon icon={faUser} />)
          },
          {
            title: "Localization",
            description: "Easily track hashtags in multiple languages to gauge localized interest.",
            image: (<FontAwesomeIcon icon={faGlobe} />)
          },
          {
            title: "Exports",
            description: "We can provide data in any shape or format you prefer, or integrate with your existing data warehouse solution.",
            image: (<FontAwesomeIcon icon={faFileExport} />)
          },
          {
            title: "Archive",
            description: "Never worry about sponsored Instagram Story content disappearing, as you start tracking hashtags you will have an archive of all content posted.",
            image: (<FontAwesomeIcon icon={faFolder} />)
          }
        ]}
      />
      <MainFeature
        subheading = "Monitor reach"
        heading={(
          <>
            Measure the impact of hashtags on <span tw="text-primary-500">Instagram Stories.</span>
          </>
        )}
        description="Estimate the reach of a instagram story by sorting and filtering based on account followers."
        
        imageSrc={screenshotB}
        imageBorder={true}
        imageDecoratorBlob={false}
        primaryButtonText={null}
      />
      <MainFeature
        subheading = "Global impact"
        heading={(
          <>
            Track hashtags <span tw="text-primary-500">globally.</span>
          </>
        )}
        description="Our clients use lozaliced hashtags to keep track of current events across the world."
        
        imageSrc={worldImage}
        imageBorder={true}
        imageDecoratorBlob={false}
        primaryButtonText={"Get Started"}
        primaryButtonUrl="mailto:mick+social@rzsg.io"
        textOnLeft={false}
      />
      <Footer />
    </StyledDiv>
  );
}
